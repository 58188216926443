
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import { notify } from '@kyvg/vue3-notification'

export default defineComponent({
  components: { TmButton, TmModal, TmFormLine },
  setup() {
    const provider = ref('')
    const modal = ref()
    const brief = ref('')
    const handleClick = () => {
      notify({
        text: 'Thank you for submitting your request to add your preferred CPaaS provider. We will keep you updated on the progress of your request.',
        type: 'success',
      })
      modal.value.hideModal()
    }

    return {
      provider,
      brief,
      modal,
      handleClick,
    }
  },
})
